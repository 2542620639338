import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BsCalendar } from 'react-icons/bs';
import './Footer.css';

const Footer = () => {
  return (
    <footer className='footer pt-2 '>
      <Container className='footer-section '>
        <Row className="justify-content-center text-left pt-5" >
          <Col md={12}>
            <br className='none' />  <br className='none' />
            <h5 className='head'>Let's bring your vision to life.</h5>
            <br />
            <Button
              variant="light"
              href="https://calendly.com/saumyacareercoach/rebel-case-prep-free-30-min-session"
              target='_blank'
              className="mt-3 book-here"
              style={{ display: 'inline-flex', alignItems: 'center', gap: 10 }}
            >
              Book a call here  <img src='images/calender.png' className="mr-2" alt='cta' />
            </Button>
          </Col>
        </Row>
        <div className="justify-content-between d-flex pb-5 text-left pt-5 mt-5 reversed">

          <p className='none'>© All Rights Reserved 2024</p>
          <div className='d-flex gap-5'>
            <p>LinkedIn</p>
            <a href='https://www.linkedin.com/in/saumyasharma2/' target='_blank' className='text-decoration-none text-white'><p>@saumyasharma2</p></a>
            <a href='https://www.linkedin.com/in/abhilaksh-sharma-39821696' target='_blank' className='text-decoration-none text-white'><p>@abhilaksh-sharma-39821696</p></a>
          </div>
          <p className='visible'>© All Rights Reserved 2024</p>
        </div>

      </Container>
    </footer>);
};
export default Footer;
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TbXboxX } from "react-icons/tb"; // Importing close icon
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white p-2 mt-3 custom-navbar container ">
      <div className="container-fluid d-flex justify-content-between align-items-center nav-container">
        <img src="images/Rebelprep.jpg" alt="Rebel Case Prep Logo" className="logo-img" width="80" height="80" />

        {/* Mobile toggle button (shown only on mobile devices) */}
        <a href='https://calendly.com/saumyacareercoach/rebel-case-prep-free-30-min-session' target='_blank'>
          <button
            className="btn gradient-button d-lg-none" // Use custom class for gradient
            type="button"
            onClick={toggleDrawer}
            aria-expanded={isOpen}
          >
            Get in touch
          </button>
        </a>
        {/* Drawer menu that is only visible on mobile devices */}
        {/* <div className={`drawer d-lg-none ${isOpen ? 'show' : 'd-none'}`} id="navbarNavMobile">
          <div className="d-flex flex-column bg-white align-items-end p-3">
          
            <button className="btn-close" onClick={toggleDrawer} aria-label="Close drawer">
              <TbXboxX />
            </button>
            <a className="nav-link mx-2" href="#">Blog</a>
            <a className="nav-link mx-2" href="#">About Us</a>
          
          </div>
        </div> */}

        {/* Desktop menu (shown only on larger screens) */}
        <div className="d-none d-lg-flex" id="navbarNav">
          <div className="ms-auto d-flex flex-lg-row flex-column align-items-center gap-3">
            <a className="nav-link mx-2" href="#">Blog</a>
            <a className="nav-link mx-2" href="#">About Us</a>
            <a href='https://calendly.com/saumyacareercoach/rebel-case-prep-free-30-min-session' target='_blank'>
              <button className="btn btn-dark custom-button">Get in touch</button>
            </a>
          </div>
        </div>
      </div>

    </nav>

  );
};

export default Navbar;

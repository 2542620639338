import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Testimonials.css';
import { FaLinkedin, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const testimonials = [
  {
    id: 1,
    name: 'Santosh Hossale',
    position: "Darden'25, McKinsey and Co",
    feedback: "Saumya helped me immensely in preparing for my McKinsey interview by conducting a thorough mock interview. Her attention to detail was remarkable, as she provided precise feedback on my responses, highlighting areas for improvement I hadn't noticed. Saumya's deep understanding of what McKinsey seeks in its associates allowed her to tailor her analysis, ensuring I was well-prepared to showcase the qualities that the firm values. Her guidance was instrumental in helping me refine my approach and boost my confidence. Her coaching is the last mile effort required to finesse your answers for top consulting firms!",
    imgSrc: 'images/SantoshHossale.jpeg',
    linkedin: 'https://www.linkedin.com/in/santoshhossale?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACW-fcQBpPfkSt7PsvpYr6NToC4ngBZWkr0&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BS5B0ckqaRa%2BQwx6druuMSQ%3D%3D'
  },
  {
    id: 2,
    name: 'Apoorva Sharma',
    position: "Bain and Company",
    feedback: "Having Abhilaksh as my case prep buddy was incredibly valuable. His consulting experience really helped me tackle different frameworks, especially the more quant-heavy cases. He also guided me through preparing for personal interview questions. His structured approach, honest feedback, and ongoing support significantly advanced my preparation. I highly recommend Abhilaksh to anyone looking for expert help with case prep and interview readiness.",
    imgSrc: 'images/ApoorvaSharma.jpeg',
    linkedin: 'https://www.linkedin.com/in/apoorva-sharma-628584111?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
  },
  {
    id: 3,
    name: 'Purvi Gupta',
    position: "Boston Consulting Group",
    feedback: "Abhilaksh's guidance was instrumental in my success with MBB interviews. His ability to simplify unconventional / abstract problem statements through first principles thinking and his unwavering support throughout the journey boosted my confidence immensely. I couldn't have done it without him!",
    imgSrc: 'images/PurviGupta.jpeg',
    linkedin: 'https://www.linkedin.com/in/purvi-gupta?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
  },
  {
    id: 4,
    name: 'Dolly Bajaj',
    position: "McKinsey and Co",
    feedback: "Abhilaksh has been a strong support throughout my journey towards consulting. His unique attitude and way of breaking down my approach and giving crisp and clear directions have been extremely instrumental in my learning. He helped me in inculcating structured thinking, and improving my communication skills by brainstorming every tactical step together, manifesting them into actions and finally resulting into multiple shortlists. His motivation and his grilling sessions both served as catalyst to nurture me into a confident professional.",
    imgSrc: 'images/DollyBajaj.jpeg',
    linkedin: 'https://www.linkedin.com/in/dolly-bajaj/'
  },
  {
    id: 5,
    name: 'Bhrigu Kapoor',
    position: "Tepper School of Business’25, Boston Consulting Group",
    feedback: "Abhilaksh's unique insights into problem-solving profoundly enhanced my case structuring. His knack for breaking down complex issues clearly and providing meticulous feedback significantly improved my preparation for the BCG interview.",
    imgSrc: 'images/BhriguKapoor.jpeg',
    linkedin: 'https://www.linkedin.com/in/bhhrigu?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
  },
  {
    id: 6,
    name: 'Rajiv Chandrasekhar',
    position: "McKinsey and Co",
    feedback: "Abhilaksh is one of the best case prep buddies out there - he takes proactive interest in your progress, and has a knack of identifying very actionable areas of development. You can be assured of not just challenging, but also very memorable fun cases!",
    imgSrc: 'images/RajivChandrasekhar.jpeg',
    linkedin: 'https://www.linkedin.com/in/rajiv-chandrasekhar-032004139?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
  }
];

const Testimonials = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [testimonialsToShow, setTestimonialsToShow] = useState(3); // Default to 3 for desktop

  useEffect(() => {
    const updateSize = () => {
      setTestimonialsToShow(window.innerWidth < 768 ? 1 : 3);
    };

    window.addEventListener('resize', updateSize);
    updateSize(); // Call on mount to set initial state

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % (testimonials.length - testimonialsToShow + 1));
  };

  const handlePrevious = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + (testimonials.length - testimonialsToShow + 1)) % (testimonials.length - testimonialsToShow + 1));
  };

  const visibleTestimonials = testimonials.slice(startIndex, startIndex + testimonialsToShow);

  return (
    <Container fluid className="pt-5 testimonials-section" id="test_slide">
      <h2 className="text-center mb-3 head">Testimonials</h2>
      <p className="text-center mb-4 para">What our happy clients say</p>

      <Row className="justify-content-around align-items-stretch testimonial-row py-5 mb-5 d-flex flex-nowrap">
        <Col xs={1} className="d-flex justify-content-center align-items-center left-arrow">
          <FaArrowLeft className="arrow-icon" onClick={handlePrevious} />
        </Col>

        {visibleTestimonials.map((testimonial, index) => (
          <Col key={testimonial.id}
            md={index === 1 ? 4 : 2} // Make the second column larger
            className={`d-flex column ${index === 1 ? 'larger' : ''}`}> {/* Add 'larger' class to the second column */}
            <Card className="testimonial-card mt-5 border-0 flex-fill">
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <img src={testimonial.imgSrc} alt={testimonial.name} className="rounded-circle testi-img" />
                    <div className="position-sec">
                      <h5 className="card-title">{testimonial.name}</h5>
                      <p className="card-subtitle">{testimonial.position}</p>
                    </div>
                  </div>
                  <Button variant="link" href={testimonial.linkedin} target="_blank">
                    <FaLinkedin className="social-icon test_linkedin" />
                  </Button>
                </div>
                <Card.Text className="mt-3 feedback">
                  {testimonial.feedback}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}


        <Col xs={1} className="d-flex justify-content-center align-items-center right-arrow">
          <FaArrowRight className="arrow-icon" onClick={handleNext} />
        </Col>
      </Row>
      <div className='testimonial-bg-img'></div>
      <br />
    </Container>
  );
};

export default Testimonials;
